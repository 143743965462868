.custom-accordion-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.custom-accordion-container-title-container {
    cursor: pointer;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.custom-accordion-container-arrow {
    padding-right: 10px;
}

.custom-accordion-container-title {
    flex: 1
}

.custom-accordion-container-body {
    flex: 1;
    padding-top: 10px;
    padding-left: 20px;
}