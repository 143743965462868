.contributor-inbox-icon {
    color: white;
    cursor: pointer;
    gap: 5px;
    display: flex;
    align-items: center;
    padding: 2px 5px;
    border: 2px solid transparent;
}

.contributor-inbox-icon-active {
    border: 2px solid var(--ansarada-green);
    border-radius: 5px;
    background: #2c2c2c;
}

.contributor-inbox-icon-disabled {
    color: #a6a6a6;
}

.contributor-inbox-popover {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
    min-width: 550px;
    max-width: 550px;
    background: white;
    box-shadow: 0px 0px 13px 0px #0000007a;
}

.contributor-inbox-home-page-filter-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-content: center;
    padding: 0 10px;
    padding-bottom: 25px;
}

@media screen and (max-width: 650px) {
    .contributor-inbox-popover {
        max-width: 90vw;
    }
    .contributor-inbox-file-item-date {
        display: none;
    }

    .contributor-inbox-home-page-filter-wrapper {
        flex-direction: column;
    }
}

.contributor-inbox-home-page-show-all-button {
    cursor: pointer;
    transition: all 0.2s;
}

.contributor-inbox-home-page-show-all-button:hover {
    padding-top: 5px;
}

.contributor-inbox-popover-top-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 10px;
    padding-bottom: 10px;
}

.contributor-inbox-popover-file-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 50vh;
    overflow: auto;
    padding-bottom: 20px;
}

.contributor-inbox-popover-refresh-icon {
    cursor: pointer;
    transition: all 0.2s !important;
}
.contributor-inbox-popover-refresh-icon:hover {
    transform: rotateZ(45deg);
}

.contributor-inbox-file-item {
    display: flex;
    flex: 1;
    background-color: white;
    justify-content: center;
    flex-direction: row;
    border-left: 4px solid transparent;
    padding: 5px;
    gap: 20px;
}
/* .contributor-inbox-file-item:hover .contributor-inbox-file-item-options {
    width: 120px;
    padding-left: 10px;
} */

.contributor-inbox-file-item:hover {
    border-left: 4px solid var(--ansarada-green);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background: #f2f2f2b3;
}

.contributor-inbox-file-item-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.contributor-inbox-file-item-main-homepage {
    flex-direction: row;
    align-items: center;
    overflow: auto;
    gap: 10px;
}

.contributor-inbox-file-item-main-homepage .contributor-inbox-file-item-date {
    min-width: 220px;
    text-align: right;
}

.contributor-inbox-file-item-size {
    width: 90px; 
    text-align: right;
}

.contributor-inbox-file-item-pageCount {
    width: 90px;
    text-align: right;
}

.contributor-inbox-file-contributor-grouping-name {
    font-weight: bold;
    cursor: pointer;
}

.contributor-inbox-file-contributor-grouping-name-icon {
    transition: all 0.2s;
}
.contributor-inbox-file-contributor-grouping-name-icon-show {
    transform: rotateY(180deg);
}

.contributor-inbox-file-item-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.contributor-inbox-file-item-details {
    color: gray;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.contributor-inbox-file-item-contributor-name {
    color: var(--ansarada-green);
    font-weight: bold;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.contributor-inbox-file-item-options {
    display: flex;
    width: 50px;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 20px; */
    padding: 0 5px;
    transition: all 0.5s;
    transition-timing-function: cubic-bezier(0.66, 0.51, 0.05, 0.89);
}

.contributor-inbox-file-item-options-show {
    /* width: auto !important; */
    /* justify-content: center; */
    /* align-items: center; */
}

.contributor-inbox-file-item-options-option {
    cursor: pointer;
    transition: opacity 0.2s;
    opacity: 1;
}

.contributor-inbox-file-item-options-option:hover {
    opacity: 0.6;
}