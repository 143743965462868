.myBarProgress {
    width: 100%;
    background-color: #f2f2f2;
}

.loadBar {
    background-color: #999999;
    height:2px;
}

.successBar {
    background-color: #1e8c1a;
    height:2px;
}
