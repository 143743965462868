.phone-control {
  border-radius: 4px;
  background: #ffffff;
  color: var(--ansarada-dark);
  -webkit-transition: box-shadow 330ms cubic-bezier(0.18,0.8,0.44,1);
  transition: box-shadow 330ms cubic-bezier(0.18,0.8,0.44,1);
  min-width: 64px;
  width: 100%;
  font-family: 'Aktiv Grotesk',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif;
  font-size: 16px;
  line-height: 24px;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  font-weight: normal;
  margin: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 11px;
  border-width: 1px;
  border-style: solid;
  border-color: #a7a7aa;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 0%) inset;
}

.phone-control:focus {
  outline:0;
}

.phone-control:hover {
  box-shadow: 0 0 0 1px rgb(0 0 0 / 0%) inset, 0 0 0 4px rgb(167 167 170 / 30%);
}

.phone-controlempty {
  font-style: italic;
}
