.binder-custom-notification-message-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 20px;
    overflow: hidden;
    max-height: 400px;
}

.binder-custom-notification-message-list-draggable-text {
    flex: 1;
    padding: 0 10px;
    color: black;
    cursor: grab;
    transition: all 0.2s;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.binder-custom-notification-message-list-draggable-text:hover {
    color: var(--ansarada-green);
}

.binder-custom-notification-message-list-title {
    font-size: 14px;
    color: var(--ansarada-light);
}

.binder-custom-notification-message-inner-notification {
    flex: 2;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
#binder-custom-notification-message-textfield,
#binder-custom-notification-message-textfield > div {
    display: flex;
    flex: 1;
}

.binder-custom-notification-message-inner-copy-list {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.binder-custom-notification-message-list-subitem-inner {
    position: relative;
}
.binder-custom-notification-message-list-subitem-subheading {
    flex: 1;
    font-size: 12px;
    font-weight: bold;
}

.binder-custom-notification-message-list-item-title {
    line-height: initial !important;
    padding-left: 0 !important;
    color: var(--ansarada-green) !important;
    font-weight: bold !important;
}

.binder-custom-notification-message-list-subitem {
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: column;
    align-items: stretch !important;
}

.binder-custom-notification-message-list-subitem-paste-icon {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}

.binder-custom-notification-message-list-subitem-copy-icon,
.binder-custom-notification-message-list-subitem-paste-icon,
.binder-custom-notification-message-list-subitem-done-icon {
    font-size: 12px;
    color: var(--ansarada-green);
}

.binder-custom-notification-message-text {
    width: 100%;
    align-content: flex-start;
    /* flex: 3 1 0%; */
    gap: 5px;
    height: 260px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    outline: none;
    border-bottom: 1px solid black;
}

.binder-custom-notification-message-text > * {
    height: 20px;
}

.binder-custom-notification-message-variable-lozenge {
    background: var(--ansarada-green);
    border-radius: 40px;
    text-align: center;
    padding: 6px 10px;
    align-items: center;
    color: white;
    font-size: 15px;
    user-select: none;
    /* height: 30px; */
    line-height: 17px;
    white-space: nowrap;
    flex: none;
    margin-left: 5px;
}
.binder-custom-notification-message-variable-lozenge:hover {
    color: white !important;
}

.binder-custom-notification-message-lozenge {
    background: var(--ansarada-green);
    border-radius: 40px;
    text-align: center;
    padding: 6px 10px;
    align-items: center;
    color: white;
    font-size: 15px;
    user-select: none;
    height: 30px;
    white-space: nowrap;
}

.binder-custom-notification-message-inner-notification .rdw-editor-toolbar{ 
    display: none;
}
.binder-custom-notification-message-inner-notification .rdw-editor-main {
    height: 360px;
    border-bottom: 1px solid black;
    display: flex;
    line-height: 35px;
}

.binder-custom-notification-message-editor-readonly {
    color: #00000061;
}

.binder-custom-notification-message-help-text {
    text-align: right;
    color: #0000009e;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 20px;
}

@media only screen and (max-height: 700px) {
    .binder-custom-notification-message-inner-notification .rdw-editor-main{
        height: 180px;
    }
    .binder-custom-notification-message-container {
        height: 200px;
    }
}