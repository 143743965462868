.copy-to-clipboard-copied {
    color: var(--ansarada-green);
    font-size: 12px;
    font-weight: bold;
}

.copy-to-clipboard-copy-icon,
.copy-to-clipboard-done-icon {
    font-size: 12px;
    color: var(--ansarada-green);
}