.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 60;
  background: rgba(255, 255, 255, 0.3);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert {}

.react-confirm-alert-svg{
  position: absolute;
  top: 0;
  left: 0;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.confirm-alert-ui {
  /*text-align: center;*/
  width: 500px;
  padding: 30px;
  background: var(--white);
  box-shadow: 0 20px 75px rgba(33, 33, 33, 0.5);
  border-radius: 5px;
  color: var(--dark-grey);
}

@media screen and (max-width: 810px) {
  .confirm-alert-ui {
    /*text-align: center;*/
    width: 100%;
    padding: 10px;
    background: var(--white);
    box-shadow: 0 20px 75px rgba(33, 33, 33, 0.5);
    border-radius: 5px;
    color: var(--dark-grey);
  }
}

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 49;
  background: rgba(51, 51, 51, 0.80);
  font-family: sans-serif, arial;
}

.confirm-helper {
  position: fixed;
  width: 400px;
  padding: 20px;
  background: var(--white);
  box-shadow: 0 20px 75px rgba(33, 33, 33, 0.5);
  border-radius: 5px;
  color: var(--dark-grey);
}

.helperZ {
  z-index: 50;
  background-color: white !important;
  border: 2px solid var(--lightish-blue) !important;
  border-radius: 5px;
  position: relative;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid white;
  margin: 0px 418px -20px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid white;
  margin-left: -30px;
}

.arrow-top {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom:10px solid white;
  margin: -30px 0px 30px;
}

.arrow-bottom {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top:10px solid white;
  position: absolute;
  bottom: -10px;
}
