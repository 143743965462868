.ansarada-board-password-text-field-endinput {
    cursor: pointer;
    padding: 0 10px;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 0.2s;
}

.ansarada-board-password-text-field {
    /* flex: 1; */
    display: flex;
    height: 56px;
    align-items: center;
}

.ansarada-board-password-text-field:hover {
    background: rgba(22, 130, 8, 0.15);
}