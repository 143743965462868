.additem{
  height: 60px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px var(--light-grey);
}

.userlist {
  padding-top: 10px;
  padding-Left: 50px;
  margin-right: 50px;
  /* height: 100%; */
  flex: 1;
  overflow-y: auto;
}

@media screen and (max-width: 810px) {
  .AddUserPanel {
    margin: 40px 10px 0px 10px !important;
  }

  .userlist {
    padding-top: 10px;
    padding-Left: 10px;
    margin-right: 10px;
    /* height: 100%; */
    flex: 1;
    overflow-y: auto;
  }
}

.AddUserPanel {
  display: flex;
  flex-direction: column;
  margin: 40px 50px 0px 50px;
}

.NoUserButRow {
  margin-top: 60px;
  display: flex;
  justify-content: space-around;
}

.btn-user {
  width: 156px;
  margin: 0 10px 0 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.uEditName {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uEditName:first-child {
  margin-right: 40px;
}

.uSpaceBet {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 700px) {
  .uEditDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .uLeftSide {
    display: flex;
    align-items: center;
  }
  .taskAlerts {
    padding: 30px;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
  }
  .groupPopSel{
    display: flex;
    flex-direction: row;
  }
}

.uEditDetails > div, .uLeftSide > div {
  width: 100%;
}

.ucell{
  margin-right: 40px;
}

.ucell-user{
  margin-right: 15px;
}

@media screen and (max-width: 400px) {
  .uEditName {
    display: flex;
    flex-direction: column;
  }

  .uEditName:first-child {
    margin-right: 0px;
  }

  /* .uEditDetails {
    display: flex;
    flex-direction: column;
  }

  .uLeftSide {
    display: flex;
    flex-direction: column;
  } */

  .uEditDetails > div, .uLeftSide > div {
    margin-bottom: 30px;
    width: 100%;
  }

  .ucell{
    margin-right: 0px !important;
  }

  /* .groupPopSel{
    display: flex;
    flex-direction: column;
  } */

  /* .groupPopSel button {
    margin-left: 0px !important;
    margin-top: 10px;
  } */
}

.editnew {
  font-style: italic;
  font-weight: normal;
  color: var(--warm-grey);
}

/*Added for browser compatibility*/
[hidden] {
  display: none;
}

.rowLine {
  border-bottom: solid 1px var(--medium-grey);
}

.aTitle {
  border-bottom: solid 1px var(--medium-grey);
  padding-bottom : 10px;
  margin-top: 60px;
}

.aLabel {
  font-size: 14px;
  font-weight: bold;
  color: var(--warm-grey);
  margin-top: 20px;
}

.passText {
  margin: 17px 0 12px;
  color: var(--dark-grey);
}

.passby {
  margin-top: 48px;
}

.adjustIcon {
  margin: -3px 10px;
}

.exitbut {
  float:right;
  color: var(--warm-grey);
  margin: 20px 20px;
}

.deviceTable table {
  font-size: 14px;
  color: var(--dark-grey);
  margin-bottom: 20px;
  border: none;
  width: 100%;
}

.deviceTable th td {
  border: none;
}

.deviceTable tr {
  text-align: left;
  box-shadow: inset 0 -1px 0 0 var(--light-grey);
}

.deviceTable td {
  height: 40px;
}

.deviceTable th {
  color: var(--warm-grey);
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
}

.device-disable {
  opacity: 0.8;
}

.resetPass {
  font-size: 14px;
  color: var(--ansarada-green);
  cursor: pointer;
}

.mobile {

}

.mobile .f-control {
  font-size: 18px;
  height: 40px;
  font-weight: normal;
  color: var(--dark-grey);
  background-color: transparent;
  border: none;
  width: 270px;
}

.manage {
  display: flex;
  justify-content: center;
}

.deskRow {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 610px) {
  .manage {
    width: 140px;
    display: flex;
    justify-content: center;
  }

  .deskRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.importRow {
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
  height: 40px;
}

.signButton{
  color: var(--lightish-blue);
}

.userlimit {
  color: var(--warm-grey);
  font-size: 16px;
}

.BinderLabels {
  margin: 10px 5px 10px 15px;
}

.BinderDrop {
  width:100%;
  letter-spacing: normal;
  background-color: transparent;
  border: 0px solid;
  margin: 10px 0px 10px;
}

.import-edit {
  font-size: 14px;
  border-radius: 4px;
  background-color: transparent;
  border: solid 1px var(--medium-grey);
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 10px;
}

.import-editEmpty {
  padding-left: 5px;
  color: var(--warm-grey);
}

.import-paste {
  width: 100%;
  height: 300px;
  text-align: center;
  border: none;
  background: transparent;
}

.grouplist {
  /*border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: hsl(0,0%,80%);*/
  min-height: 38px;
  min-width: 300px;
  /*outline: 0 !important;
  position: relative;*/
}

@media screen and (max-width: 480px) {
  .recoveryCardNeeded {
    font-size: 10px;
    margin-left: 10px;
    text-decoration: none;
    color: var(--red);
  }
}

@media screen and (min-width: 481px) {
  .recoveryCardNeeded {
    font-size: 12px;
    margin-left: 10px;
    text-decoration: none;
    color: var(--red);
  }
}

.groupitem{
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid var(--medium-grey);
  border-radius: 30px;
  background-color: var(--white);
  padding-left: 5px;
  margin: 10px;
}

.groupCol {
  border-right: 1px solid var(--medium-grey);
}

.group-content {
  background-color: var(--very-light-grey);
  border-radius: 4px;
  font-size: 14px;
  padding: 30px 0px 30px 0px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.ghight {
  height: 500px;
}

@media only screen and (max-height: 1000px) {
  .ghight {
    height: 20vh;
  }
}

.groupLeft{
  direction: rtl;
  height: 510px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.groupLeftR {
  direction: ltr;
}

.groupRight{
  height: 510px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.userRowFirst {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.userRowLast {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.groupname{
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

.groupLabel {
  font-size: 20px;
  font-weight: bold;
}

.bNewUser-Title {
  /*border-bottom: solid 1px var(--light-grey);*/
  padding-bottom: 20px;
}

.newUserOption {
  justify-content: flex-start;
}

.newUserOption label {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.newUserOption div {
  font-size: 14px;
  color: var(--warm-grey);
  margin-top: 10px;
}

.taskAlerts {
  padding: 30px;
  background-color: var(--white);
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}

.taskCode {
  font-size: 40px;
  font-weight: bold;
  color: var(--lightish-blue);
  margin-right: 100px;
}

.boardWrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.companyeditlogo{
  width: 80px;
  height: 80px;
  position: relative;
}

.companyeditlogo:focus {outline:0;}

.custSetting {
  margin: 20px 0px;
}

.custSetting h3 {
  padding: 0;
  margin: 0;
}

.custSetting label {
  color: var(--warm-grey);
  margin-bottom: 5px;
}

.selectLink {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: var(--lightish-blue);
  font-size: 14px;
  width: 100px;
}

@media screen and (max-width: 410px) {
  .selectLink {
    display: none;
  }
}

.custTitle {
  width: 100%;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-grey);
  background-color: transparent;
  border: 0px solid;
}

.custLabel {
  font-size: 14px;
  color: var(--warm-grey);
  border-bottom: 1px solid var(--light-grey);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 50px;
}

.custLabel > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custLabel h3 {
  font-weight: bold;
  font-size: 14px;
  color: var(--dark-grey);
  padding: 0;
  margin: 0;
}

.custLabel span {
  color: var(--dark-grey);
}

.custLabel label {
  color: var(--warm-grey);
}

.lockpassLogo {
  width: 20px;
  margin-left: 10px;
}

.tempcode {
  font-weight: bold;
  font-size: 18px;
  color: var(--dark-grey);
}

.tempExpire {
  color: var(--ansarada-light);
  /* font-style: italic; */
}

.invitedUser {
  display: flex;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  font-size: 18px;
}

.invitedUser span {
  font-weight: bold;
}

.uWait {
  color: var(--dark-grey);
  font-size: 16px;
  margin-top: 25px;
  /* font-style: italic; */
}

.ficonr {
  float: right;
  margin-right: 20px;
}

.userListp {
  flex: 1 1 auto;
}

.userListp-inner {
  flex: 1; 
  display: flex; 
  overflow: hidden; 
  padding-bottom: 80px;
}

.userTiles {
  display: flex;
  flex-wrap: wrap;
}

.userTile {
  width: 150px;
  height: 240px;
  border: 1px solid var(--ansarada-grey);
  border-radius: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Logo */
.squarelogo{
  width: 99.99%;
  height: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.squareChar {
  background-color: var(--light-grey);
  font-size: 32px;
  font-weight: bold;
  color: var(--warm-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 99.99%;
}

.tileLabel {
  font-size: 12px;
  color: var(--ansarada-light);
  margin-left: 5px;
}

.searchInput {
  border: 1px solid var(--ansarada-grey);
  border-radius: 5px;
  padding-left: 10px;
}

.searchInpute {
  font-style: italic;
  color: var(--ansarada-grey);
}

.sort {
  border: none;
  background-color: transparent;
  color: var(--ansarada-green);
}

.tileCheck {
  margin: -170px 5px 110px;
  z-index: 3;
}

.rowCheck {
  width: 20px;
}

.peoplePanel-container {
  padding: 30px 50px 0 50px;
}

@media screen and (max-width: 810px) {
  .peoplePanel-container {
    padding: 20px 10px 0 10px;
  }
}