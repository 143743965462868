.reacttour {

}

.tourbg {
  position: absolute;
  z-index: 50;
  background-color: #00000099;
}

.blockbg {
  position: fixed;
  z-index: 50;
}

.helper{
  line-height: 1.3;
  position: absolute;
  background-color: #fff;
  transition: transform 0.3s;
  padding: 24px 30px;
  box-shadow: 0 0.5em 3em rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  color: inherit;
  z-index: 50;
  max-width: 331px;
  min-width: 150px;
  padding-right: 40px;
  border-radius: 5px;
  font-size: 16px;
}

.badge {
  position: absolute;
  background-color: var(--lightish-blue);
  height: 1.875em;
  line-height: 2;
  padding-left: 0.8125em;
  padding-right: 0.8125em;
  font-size: 1em;
  border-radius: 1.625em;
  color: white;
  text-align: center;
  box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.3);
  top: -0.8125em;
  left: -0.8125em;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.tourdot {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-right: 5px;
}

.tourdotEmpty {
  height: 10px;
  width: 10px;
  background-color: #f9f9f9;
  border-radius: 50%;
  border: solid 1px #d4d4d4;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.tourdotEmpty:hover {
  background-color: #ccc;
}

.tourarrow{
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}
