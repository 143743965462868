.primaryNeedPopup-video-section {
    height: 400px;
    max-height: 55vh;
}

.primaryNeedPopup-text-section {
    min-height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    overflow: auto;
}

.primaryNeedPopup-text-section > h2 {
    text-align: center;
}

.freemium-book-a-demo {
    color: var(--ansarada-green);
}

@media screen and (max-width: 700px) {
    .primaryNeedPopup-text-section {
        padding: 20px 40px;
    }
}